import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  TableHeaderFont,
} from "../../../../styles/tableContent.style";
import { useSelector } from "react-redux";
import { EmptyContainer } from "../../../../utilities/helper";

const InitiatedContent = ({ setPage, setPer_page, page, per_page }) => {
  const { loading, initiatedPagination, initiatedVendorData } = useSelector(
    (state) => state.pendinglist
  );
  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };
  return (
    <>
      <CustomTableContainer component={Card}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table /*  sx={{ minWidth: 650 }} */ aria-label="simple table">
            <CustomTableHead>
              <TableCell>
                <TableHeaderFont>Vendor Name</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Legal Name</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>Company Email</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>PAN</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>creater name</TableHeaderFont>
              </TableCell>
              <TableCell>
                <TableHeaderFont>createdAt</TableHeaderFont>
              </TableCell>
            </CustomTableHead>
            <TableBody>
              {initiatedVendorData.length !== 0 ? (
                initiatedVendorData.map((row) => {
                  return (
                    <>
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          <TableContentFont>
                            {row.first_name
                              ? `${row.first_name} ${row.last_name}`
                              : `Not Applicable`}
                          </TableContentFont>
                        </TableCell>
                        <TableCell align="left">
                          <TableContentFont>
                            {row.legal_name
                              ? `${row.legal_name}`
                              : `Not Applicable`}
                          </TableContentFont>
                        </TableCell>
                        <TableCell align="left">
                          <TableContentFont>
                            {row.company_email
                              ? `${row.company_email}`
                              : `Not Applicable`}
                          </TableContentFont>
                        </TableCell>
                        <TableCell align="left">
                          <TableContentFont>
                            {row.permanent_account_number
                              ? `${row.permanent_account_number}`
                              : `Not Applicable`}
                          </TableContentFont>
                        </TableCell>
                        <TableCell align="left">
                          <TableContentFont>
                            {row?.creater_name
                              ? `${row.creater_name}`
                              : `Not Applicable`}
                          </TableContentFont>
                        </TableCell>
                        <TableCell align="left">
                          <TableContentFont>
                            {row.updatedAt !== null
                              ? moment(row.updatedAt).format("DD MMM YY")
                              : "Not Applicable"}
                          </TableContentFont>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <CustomTableContainer>
                      <EmptyContainer text={"There are no data to Display."} />
                    </CustomTableContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        <Grid
          container
          className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
          sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
        >
          <Grid className="d-flex align-items-center gap-2">
            <span>Rows per page: </span>
            <select
              value={per_page}
              onChange={(e) => setPer_page(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
          <Pagination
            sx={{
              width: "max-content",
            }}
            color="primary"
            variant="text"
            onChange={(_, newValue) => setPage(newValue)}
            count={initiatedPagination?.total_page}
            page={page}
            shape="rounded"
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Grid>
      </CustomTableContainer>
    </>
  );
};

export default InitiatedContent;
