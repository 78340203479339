import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Pagination,
  TableFooter,
  TablePagination,
  Tooltip,
  useTheme,
} from "@mui/material";

import Chip from "@mui/material/Chip";

import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  RemoveRedEye,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { FirstPage, LastPage, Send } from "@mui/icons-material";
import moment from "moment";
import { Link } from "react-router-dom";
import LocalStorage from "../../../utilities/localStorage";
import { EmptyContainer } from "../../../utilities/helper";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function AdminPrsTableContent({ setPage, setPer_page, page, per_page }) {
  const { prsList, loading, prsListPagination } =
    useSelector((state) => state.adminPrs) ?? [];

  const ClientId = LocalStorage.getItem("client").id;
  const role = LocalStorage.getItem("tata_login_user").roles[0];
  const loggedInUserId = LocalStorage.getItem("tata_login_user").user_id;

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };
  return (
    <CustomTableContainer component={Card}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table aria-label="simple table">
          <CustomTableHead>
            <TableRow>
              <TableCell align="left">
                <TableHeaderFont>PR Number</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Desired Delivery Date</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Date</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Urgency Level</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Status</TableHeaderFont>
              </TableCell>
              <TableCell align="left">
                <TableHeaderFont>Total Items</TableHeaderFont>
              </TableCell>
              {ClientId === 5 && (
                <TableCell align="left">
                  <TableHeaderFont>PR Amount</TableHeaderFont>
                </TableCell>
              )}

              {/* {filter === "All" && (
              <>
                <TableCell align="left">
                  <TableHeaderFont>Pending Items</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Approved Items</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Rejected Items</TableHeaderFont>
                </TableCell>
              </>
            )} */}
              <TableCell align="center">
                <TableHeaderFont>Action</TableHeaderFont>
              </TableCell>
            </TableRow>
          </CustomTableHead>
          <TableBody>
            {prsList.length !== 0 ? (
              prsList.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <TableContentFont>
                        {row.pr_number !== null
                          ? row.pr_number
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                    <TableCell>
                      <TableContentFont>
                        {row.desired_delivery_date !== null
                          ? moment(row.desired_delivery_date).format(
                              "DD MMM YY"
                            )
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                    <TableCell>
                      <TableContentFont>
                        {row.createdAt !== null
                          ? moment(row.createdAt).format("DD MMM YY")
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                    <TableCell>
                      <TableContentFont>
                        {row.urgency_level !== null
                          ? row.urgency_level.toUpperCase()
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                    <TableCell>
                      {row.status !== null ? (
                        <Chip
                          label={row.status.toUpperCase()}
                          color={
                            row.status === "pending"
                              ? "warning"
                              : row.status === "completed"
                              ? "success"
                              : row.status === "rejected"
                              ? "error"
                              : "default"
                          }
                          size="small"
                          style={{ fontSize: "9px" }}
                        />
                      ) : (
                        "Not Applicable"
                      )}
                    </TableCell>
                    <TableCell>
                      <TableContentFont>
                        {row.materials.length !== 0
                          ? row.materials.length
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>
                    {/* {filter === "All" && (
                <>
                  <TableCell>
                    <TableContentFont>
                      {row.materials !== null
                        ? row.materials.filter(
                            (item) => item.material_status === "pending"
                          ).length
                        : "Not Applicable"}
                    </TableContentFont>
                  </TableCell>
                  <TableCell>
                    <TableContentFont>
                      {row.materials !== null
                        ? row.materials.filter(
                            (item) => item.material_status === "approved"
                          ).length
                        : "Not Applicable"}
                    </TableContentFont>
                  </TableCell>
                  <TableCell>
                    <TableContentFont>
                      {row.materials !== null
                        ? row.materials.filter(
                            (item) => item.material_status === "rejected"
                          ).length
                        : "Not Applicable"}
                    </TableContentFont>
                  </TableCell>
                </>
              )} */}
                    {ClientId === 5 && (
                      <TableCell>
                        <TableContentFont>
                          {row.pr_amount ? row.pr_amount : "Not Applicable"}
                        </TableContentFont>
                      </TableCell>
                    )}
                    <TableCell style={{ textAlign: "center" }}>
                      <Tooltip title="View PR">
                        <Link
                          style={{ textDecoration: "none" }}
                          // eslint-disable-next-line
                          to={"/app/prs-list-view/" + `${row.id}`}
                        >
                          <RemoveRedEye
                            style={{
                              fill: "royalblue",
                              height: "18px",
                              width: "18px",
                            }}
                          />
                        </Link>
                      </Tooltip>{" "}
                      {/* 
                If the PR's status is completed, the client is Client3 or Client5, and the user's role is officer,
                render a tooltip with a link to review the PR and push it to sourcing items. 
                If not, render nothing.
                */}
                      {(row.status === "completed" &&
                        ClientId === 3 &&
                        role === "officer" &&
                        loggedInUserId === row.user_id &&
                        row.materials.filter(
                          (item) => item.material_status === "approved"
                        ).length !== 0) ||
                      (row.status === "completed" &&
                        ClientId === 5 &&
                        (role === "officer" || role === "Admin") &&
                        loggedInUserId === row.user_id &&
                        row.materials.filter(
                          (item) => item.material_status === "approved"
                        ).length !== 0) ? (
                        <>
                          {/* The tooltip that appears on hover */}
                          <Tooltip title="Review Push to Sourcing Items">
                            {/* Link to the review and push page of the PR */}
                            <Link
                              style={{ textDecoration: "none" }}
                              // eslint-disable-next-line
                              to={"/app/prs-push-view/" + `${row.id}`}
                            >
                              {/* SVG icon for sending */}
                              <Send
                                style={{
                                  fill: "royalblue",
                                  height: "18px",
                                  width: "18px",
                                }}
                              />
                            </Link>
                          </Tooltip>
                        </>
                      ) : (
                        // If the conditions are not met, render nothing
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <CustomTableContainer>
                    <EmptyContainer
                      text={"There are no purchase requisitions to display"}
                    />
                  </CustomTableContainer>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      <Grid
        container
        className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
        sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
      >
        <Grid className="d-flex align-items-center gap-2">
          <span>Rows per page: </span>
          <select
            value={per_page}
            onChange={(e) => setPer_page(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </Grid>
        <Pagination
          sx={{
            width: "max-content",
          }}
          color="primary"
          variant="text"
          onChange={(_, newValue) => setPage(newValue)}
          count={prsListPagination?.total_page}
          page={page}
          shape="rounded"
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Grid>
    </CustomTableContainer>
  );
}

export default AdminPrsTableContent;
