import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  IconButton,
  useTheme,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Grid,
  CircularProgress,
  Pagination,
} from "@mui/material";

import {
  CustomTableContainer,
  CustomTableHead,
  TableContentFont,
  TableContentVendorFont,
  TableHeaderFont,
} from "../../../../styles/tableContent.style";
import { useDispatch, useSelector } from "react-redux";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Send,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import moment from "moment";
import { Link } from "react-router-dom";
import LocalStorage from "../../../../utilities/localStorage";
import { useForm } from "react-hook-form";
import { fetchVendorCode, pushSAP } from "../../redux/adminTableSlice";
import { EmptyContainer } from "../../../../utilities/helper";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <>
      <Box sx={{ flexShrink: 0 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function ApprovedContent({ setPage, setPer_page, page, per_page }) {
  const {
    VendorData,
    loading: tableLoading,
    VendorCodeWithCompanyName,
    pagination,
  } = useSelector((state) => state.pendinglist);
  const [open, setOpen] = React.useState(false);
  const [openVendorCode, setOpenVendorCode] = React.useState(false);
  const [sapPayload, setSapPayload] = React.useState({
    company_code: "",
    vid: "",
  });
  const [vid, setVId] = React.useState();
  const role = LocalStorage.getItem("tata_login_user").roles[0];
  const clientId = LocalStorage.getItem("client")?.id;

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(pushSAP(sapPayload)).then((res) => {
      setOpen(false);
      setOpenVendorCode(false);
    });
  };
  const fetchVendorCodes = (id) => {
    dispatch(fetchVendorCode(id));
  };

  return (
    <>
      <CustomTableContainer component={Card}>
        {tableLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table /*  sx={{ minWidth: 650 }} */ aria-label="simple table">
            <CustomTableHead>
              <TableRow>
                <TableCell>
                  <TableHeaderFont>Vendor Name</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>PAN</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>GST</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Date</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor ID</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor Code</TableHeaderFont>
                </TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {VendorData.length !== 0 ? (
                VendorData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/vendor-registration-view/" + `${row.id}`}
                      >
                        <TableContentVendorFont>
                          {typeof row.company_name !== undefined &&
                          row.company_name !== null
                            ? `${row.company_name}`
                            : `Not Applicable`}
                        </TableContentVendorFont>
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.permanent_account_number !== undefined &&
                        row.permanent_account_number !== null
                          ? `${row.permanent_account_number}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont style={{ textTransform: "capitalize" }}>
                        {typeof row.gst_registration_type !== undefined &&
                        row.gst_registration_type !== null
                          ? `${row.gst_registration_type}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.updatedAt !== null
                          ? moment(row.updatedAt).format("DD MMM YY")
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>

                    <TableCell align="left">
                      <TableContentFont> {row.id}</TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {clientId === 2 ? (
                          <>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                setVId(row.id);
                                setOpenVendorCode(true); // Open the vendor code dialog
                                fetchVendorCodes(row.id); // Fetch vendor codes for the clicked row's ID
                              }}
                            >
                              Vendor Code
                            </Button>
                          </>
                        ) : typeof row.vendor_code !== undefined &&
                          row.vendor_code !== null ? (
                          `${row.vendor_code}`
                        ) : (
                          "Not Applicable"
                        )}
                      </TableContentFont>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <CustomTableContainer>
                      <EmptyContainer text={"There is no data to display."} />
                    </CustomTableContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
        <Grid
          container
          className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
          sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
        >
          <Grid className="d-flex align-items-center gap-2">
            <span>Rows per page: </span>
            <select
              value={per_page}
              onChange={(e) => setPer_page(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Grid>
          <Pagination
            sx={{
              width: "max-content",
            }}
            color="primary"
            variant="text"
            onChange={(_, newValue) => setPage(newValue)}
            count={pagination?.total_page}
            page={page}
            shape="rounded"
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton
            showLastButton
          />
        </Grid>
      </CustomTableContainer>

      {/* view vendors code and buyer company list only for admin*/}
      <Dialog
        open={openVendorCode}
        onClose={() => setOpenVendorCode(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Vendor Code List</DialogTitle>
        <DialogContent dividers="pepar">
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <CustomTableHead>
                <TableRow>
                  <TableCell>
                    <TableHeaderFont>Buyer Company</TableHeaderFont>
                  </TableCell>
                  <TableCell>
                    <TableHeaderFont>Vendor Code</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {VendorCodeWithCompanyName.length
                  ? VendorCodeWithCompanyName.map((row) => {
                      return (
                        <TableRow>
                          <TableCell>{row.Company?.company_name}</TableCell>
                          <TableCell>
                            {row?.vendor_code ? (
                              row?.vendor_code
                            ) : role === "Admin" ? (
                              <Button
                                variant="contained"
                                sx={{ backgroundColor: "#4059e5" }}
                                endIcon={<Send />}
                                onClick={() => {
                                  setSapPayload({
                                    vid,
                                    company_code: row.Company?.company_code,
                                  });
                                  setOpen(true);
                                }}
                              >
                                Push
                              </Button>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : "No data found"}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* Send to SAP confirm box only for admin*/}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move", fontWeight: "Bold" }}>
          Push to SAP
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Click confirm to push to SAP</DialogContentText>
          <br />
          <DialogContentText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  autoFocus
                  onClick={() => setOpen(false)}
                  style={{ height: "40px", border: "#4059e5 solid 1px" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    textTransform: "none",
                    background: "#4059e5",
                    width: "200px",
                    height: "40px",
                  }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={tableLoading}
                  loadingPosition="start"
                >
                  CONFIRM
                </LoadingButton>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}></DialogActions>
      </Dialog>
    </>
  );
}
export default ApprovedContent;

export function ApprovedContentForOfficer({
  setPage,
  setPer_page,
  page,
  per_page,
}) {
  const {
    approved,
    VendorCodeWithCompanyName,
    approved_pagination: pagination,
    loading,
  } = useSelector((state) => state.pendinglist);

  const [openVendorCode, setOpenVendorCode] = React.useState(false);
  const dispatch = useDispatch();
  const clientId = LocalStorage.getItem("client")?.id;

  const fetchVendorCodes = (id) => {
    dispatch(fetchVendorCode(id));
  };

  const handleChangeRowsPerPage = (event) => {
    setPer_page(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <CustomTableContainer component={Card}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table /*  sx={{ minWidth: 650 }} */ aria-label="simple table">
            <CustomTableHead>
              <TableRow>
                <TableCell>
                  <TableHeaderFont>Vendor Name</TableHeaderFont>
                </TableCell>

                <TableCell align="left">
                  <TableHeaderFont>PAN</TableHeaderFont>
                </TableCell>

                <TableCell align="left">
                  <TableHeaderFont>GST</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Date</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor ID</TableHeaderFont>
                </TableCell>
                <TableCell align="left">
                  <TableHeaderFont>Vendor Code</TableHeaderFont>
                </TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody>
              {approved.length !== 0 ? (
                approved.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link
                        style={{ textDecoration: "none" }}
                        // eslint-disable-next-line
                        to={"/app/vendor-registration-view/" + `${row.id}`}
                      >
                        <TableContentVendorFont>
                          {typeof row.company_name !== undefined &&
                          row.company_name !== null
                            ? `${row.company_name}`
                            : `Not Applicable`}
                        </TableContentVendorFont>
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {typeof row.permanent_account_number !== undefined &&
                        row.permanent_account_number !== null
                          ? `${row.permanent_account_number}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont style={{ textTransform: "capitalize" }}>
                        {typeof row.gst_registration_type !== undefined &&
                        row.gst_registration_type !== null
                          ? `${row.gst_registration_type}`
                          : `Not Applicable`}
                      </TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {row.updatedAt !== null
                          ? moment(row.updatedAt).format("DD MMM YY")
                          : "Not Applicable"}
                      </TableContentFont>
                    </TableCell>

                    <TableCell align="left">
                      <TableContentFont> {row.id}</TableContentFont>
                    </TableCell>
                    <TableCell align="left">
                      <TableContentFont>
                        {clientId === 2 ? (
                          <>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                setOpenVendorCode(true); // Open the vendor code dialog
                                fetchVendorCodes(row.id); // Fetch vendor codes for the clicked row's ID
                              }}
                            >
                              Vendor Code
                            </Button>
                          </>
                        ) : typeof row.vendor_code !== undefined &&
                          row.vendor_code !== null ? (
                          `${row.vendor_code}`
                        ) : (
                          "Not Applicable"
                        )}
                      </TableContentFont>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <CustomTableContainer>
                      <EmptyContainer text={"There is no data to display."} />
                    </CustomTableContainer>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}

        {approved.length !== 0 ? (
          <Grid
            container
            className="d-flex flex-row align-items-center justify-content-between w-100 px-3 text-muted"
            sx={{ height: "10vh", borderTop: "1px solid lightgray" }}
          >
            <Grid className="d-flex align-items-center gap-2">
              <span>Rows per page: </span>
              <select
                value={per_page}
                onChange={(e) => setPer_page(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </Grid>
            <Pagination
              sx={{
                width: "max-content",
              }}
              color="primary"
              variant="text"
              onChange={(_, newValue) => setPage(newValue)}
              count={pagination?.total_page}
              page={page}
              shape="rounded"
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Grid>
        ) : (
          ""
        )}
      </CustomTableContainer>
      {/* view vendors code and buyer company list only for admin*/}
      <Dialog
        open={openVendorCode}
        onClose={() => setOpenVendorCode(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Vendor Code List</DialogTitle>
        <DialogContent dividers="pepar">
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <CustomTableHead>
                <TableRow>
                  <TableCell>
                    <TableHeaderFont>Buyer Company</TableHeaderFont>
                  </TableCell>
                  <TableCell>
                    <TableHeaderFont>Vendor Code</TableHeaderFont>
                  </TableCell>
                </TableRow>
              </CustomTableHead>
              <TableBody>
                {VendorCodeWithCompanyName.length
                  ? VendorCodeWithCompanyName.map((row) => {
                      return (
                        <TableRow>
                          <TableCell>{row.Company?.company_name}</TableCell>
                          <TableCell>
                            {row?.vendor_code ? row?.vendor_code : "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : "No data found"}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
