import loginReducer from "../features/login/redux/loginSlice";
import signupReducer from "../features/signup/redux/signup/signupSlice";
import forgotPasswordReducer from "../features/forgotPassword/redux/forgotPasswordSlice";
import panReducer from "../features/signup/redux/pan/panSlice";
import vendorRegistrationReducer from "../features/vendorRegistration/redux/vendorRegistrationSlice";
// import addContactReducer from "../features/vendorRegistration/redux/addContactSlice";
import createNewReducer from "../features/createNewPassword/redux/createNewPasswordSlice";
import bankdetailsReducer from "../features/bankDetails/redux/bankDetailsSlice";
import dashBoardDetailsReducer from "../features/dashboard/redux/dashboardSlice";
import getPendingReducer from "../features/adminTable/redux/adminTableSlice";
import changePasswordReducer from "../features/accountSettings/redux/accountSettingsSlice";
import adminOrdersReducer from "../features/adminOrders/redux/adminOrdersSlice";
import invoiceModuleReducer from "../features/invoiceModule/redux/invoiceModuleSlice";
import userOrdersReducer from "../features/userOrders/redux/userOrdersSlice";
import adminInvoicesReducer from "../features/adminInvoices/redux/adminInvoicesSlice";
import companyReducer from "../features/company/redux/companySlice";
import itemReducer from "../features/item/redux/itemSlice";
import companiesUserReducer from "../features/companiesUser/redux/companiesUserSlice";
import sendInvitationReducer from "../features/adminTable/redux/sendLinkSlice";
import addGlobleFinance from "../features/admin_authirities/redux/AdminAuthoritiesSlice";
import notificationReducer from "../features/notification/redux/notificationSlice";
import adminGrnsReducer from "../features/adminGrns/redux/adminGrnsSlice";
import inspectionModuleReducer from "../features/inspectionModule/redux/inspectionModuleSlice";
import userSchedulesReducer from "../features/userSchedules/redux/userSchedulesSlice";
import adminSchedulesReducer from "../features/adminSchedules/redux/adminSchedulesSlice";
import adminAsnsReducer from "../features/adminAsns/redux/adminAsnsSlice";
import asnModuleReducer from "../features/asnModule/redux/asnModuleSlice";
import scheduleModuleReducer from "../features/scheduleModule/redux/scheduleModuleSlice";
import lotModuleReducer from "../features/lotModule/redux/lotModuleSlice";
import userLotsReducer from "../features/userLots/redux/userLotsSlice";
import userGrnsReducer from "../features/userGrns/redux/userGrnsSlice";
import adminLotsReducer from "../features/adminLots/redux/adminLotsSlice";
import qualityCheckReducer from "../features/qualityCheck/redux/qualityCheckSlice";
import userPrivilegesReducer from "../features/userPrivileges/redux/userPrivilegesSlice";
import adminPrsReducer from "../features/adminPrs/redux/adminPrsSlice";
import departmentModuleReducer from "../features/departments/redux/departmentSlice";
import userWorkflowsReducer from "../features/workflows/redux/userWorkflowsSlice";

const rootReducer = {
  login: loginReducer,
  signup: signupReducer,
  forgotPassword: forgotPasswordReducer,
  pan: panReducer,
  vendorRegistration: vendorRegistrationReducer,
  createNewPassword: createNewReducer,
  bankdetails: bankdetailsReducer,
  dashboarddetails: dashBoardDetailsReducer,
  pendinglist: getPendingReducer,
  changepassword: changePasswordReducer,
  adminOrders: adminOrdersReducer,
  invoiceModule: invoiceModuleReducer,
  userOrders: userOrdersReducer,
  adminInvoices: adminInvoicesReducer,
  company: companyReducer,
  companiesUser: companiesUserReducer,
  sendInvitationLink: sendInvitationReducer,
  addGlobleFinance: addGlobleFinance,
  notification: notificationReducer,
  adminGrns: adminGrnsReducer,
  inspectionModule: inspectionModuleReducer,
  userSchedules: userSchedulesReducer,
  adminSchedules: adminSchedulesReducer,
  adminAsns: adminAsnsReducer,
  asnModule: asnModuleReducer,
  scheduleModule: scheduleModuleReducer,
  lotModule: lotModuleReducer,
  userLots: userLotsReducer,
  userGrns: userGrnsReducer,
  adminLots: adminLotsReducer,
  qualityCheck: qualityCheckReducer,
  userPrivileges: userPrivilegesReducer,
  adminPrs: adminPrsReducer,
  userWorkflows: userWorkflowsReducer,
  departmentModule: departmentModuleReducer,
  item: itemReducer,
};
export default rootReducer;
