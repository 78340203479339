import {
  Box,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useState, useEffect } from "react";
import {
  CustomButton,
  DocumentLabels,
  SelectSpan,
  StyledCard,
  StyledOutlineButton,
  VendorCardLabel,
} from "../../vendorRegistration/components/StepMain";
import {
  CustomTableContainer,
  TableHeaderFont,
} from "../../../styles/tableContent.style";
import {
  Super,
  VendorCardSubTitle,
} from "../../vendorRegistration/components/steps/cardTrial";
import { VendorCardTitle } from "../../vendorRegistration/components/steps/vendorReg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useForm } from "react-hook-form";
import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import { getMaterials, createPr, resetMatrials } from "../redux/adminPrsSlice";
import {
  getApprovers,
  getPersons,
  getPrWorkflowDetails,
} from "../../workflows/redux/userWorkflowsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { history } from "../../../app/history";
import LocalStorage from "../../../utilities/localStorage";
import { Company } from "../../../CONSTANTS";
// import AutoComplate from "../../../common/sharedComponents/AutoComplate";
import { getCompanyListForPr } from "../../adminTable/redux/sendLinkSlice";
import GlobalAutocomplete from "../../../common/sharedComponents/AutoComplate";
import { getPurchasePoints } from "../../company/redux/companySlice";

const Input = styled("input")({
  display: "none",
});

function AdminPrsContent() {
  const dispatch = useDispatch();
  const { materials } = useSelector((state) => state.adminPrs);
  const { approvers, persons, pr_workflow_details } = useSelector(
    (state) => state.userWorkflows
  );
  const { companyListForPr } = useSelector((state) => state.sendInvitationLink);
  const { purchasePointList } = useSelector((state) => state.company);
  const [datevalue, setDatevalue] = useState(Date);
  const [prDoc, setPrDoc] = useState("");
  const [newDocs, setNewDocs] = useState([1]);
  const [supportingDoc, setSupportingDoc] = useState([]);
  const [items, setItems] = useState([]);
  const [itemswf, setItemswf] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [preDefinedWorkflowSteps, setPreDefinedWorkflowSteps] = useState([]);
  const [selection, setSelection] = useState("role");
  const [wfSelection, setWfSelection] = useState("manual");
  const [expenseType, setExpenseType] = useState("opex");

  const item_list = materials || [];
  const item_list_wf = selection === "role" ? approvers : persons;

  const user = LocalStorage.getItem("tata_login_user");
  const clientId = LocalStorage.getItem("client")?.id;
  const roles = user.roles;
  const role = roles[0];

  const [shippingAddress, setShippingAddress] = useState(
    clientId === 5
      ? "JASLOK HOSPITAL & RESEARCH CENTRE 15 DR G. DESHMUKH MARG, MUMBAI -400026"
      : ""
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const getData = () => {
    dispatch(getApprovers());
    dispatch(getPersons());
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const viewPrWorkflowDetails = () => {
    dispatch(getPrWorkflowDetails());
    dispatch(getPurchasePoints(0));
  };

  useEffect(() => {
    viewPrWorkflowDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getCompanyListForPr());
    // eslint-disable-next-line
  }, [dispatch]);

  const handleOnchange = (e) => {
    let workflow_id = e.target.value;
    let matchedWorkflow = pr_workflow_details.find(
      (workflow) => workflow.id === workflow_id
    );
    setPreDefinedWorkflowSteps(matchedWorkflow?.steps);
  };

  const handleShippingChange = (event) => {
    setShippingAddress(event.target.value);
    setValue("shipping_address", event.target.value);
  };

  const handleSelectionChange = (event) => {
    setSelection(event.target.value);
  };

  const handleWfsSelectionChange = (event) => {
    setWfSelection(event.target.value);
    if (event.target.value === "manual") {
      setPreDefinedWorkflowSteps([]);
    }
  };

  const handleExpenseSelectionChange = (event) => {
    setExpenseType(event.target.value);
  };

  const handleOnClick = () => {
    let docsCopy = [...newDocs];

    docsCopy.push({
      supporting_docs: "",
    });

    setNewDocs(docsCopy);
  };

  const handleinvdate = (e) => {
    setDatevalue(e.target.value);
  };

  const handleAddItem = () => {
    const newItem = {
      quantity: 0,
      unit_of_measurement: "",
      frequency: "",
      item_name: "",
      purpose: "",
    };

    setItems([...items, newItem]);
    dispatch(resetMatrials());
  };

  const handleAddItemWF = () => {
    let newItem;
    if (selection === "role") {
      newItem = {
        role_name: "",
      };
    } else {
      newItem = {
        contact_person_email: "",
      };
    }

    setItemswf([...itemswf, newItem]);
  };

  const handleRemove = (e, i) => {
    let uploadeddocs = [...newDocs];
    uploadeddocs.splice(i, 1);
    setNewDocs(uploadeddocs);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handleRemoveItemWF = (index) => {
    const updatedItems = [...itemswf];
    updatedItems.splice(index, 1);

    // Remove the corresponding email from the selectedEmails array
    setSelectedEmails((prevSelectedEmails) => {
      const newSelectedEmails = [...prevSelectedEmails];
      newSelectedEmails.splice(index, 1); // Remove the email at the same index
      return newSelectedEmails;
    });

    setItemswf(updatedItems);
  };

  const handleInputChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };

  const handleInputChangewf = (index, field, value) => {
    const updatedItems = [...itemswf];
    updatedItems[index][field] = value;

    // Update selected emails array
    setSelectedEmails((prevSelectedEmails) => {
      const newSelectedEmails = [...prevSelectedEmails];
      newSelectedEmails[index] = value;
      return newSelectedEmails;
    });

    setItemswf(updatedItems);
  };

  const handlechangeDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: e.target.files[0] };

    setSupportingDoc(newDocValues);
  };

  const handleclearDoc = (e, index) => {
    let newDocValues = { ...supportingDoc, [index]: "" };

    setSupportingDoc(newDocValues);
  };

  const handleSave = (data) => {
    // console.log("data :", data);
    setLoading(true);
    let pr_details = [
      {
        company_id: data?.company_id?.key,
        pr_number: data.pr_number,
        pr_amount: parseFloat(data.pr_amount),
        desired_delivery_date: data.desired_delivery_date,
        shipping_address:
          clientId === 5 ? shippingAddress : data.shipping_address,
        billing_address: data.billing_address,
        items: items,
        preferred_supplier: data.preferred_supplier,
        reason_of_purchase: data.reason_of_purchase,
        urgency_level: data.urgency_level,
        remarks: data.remarks,
        approvers: wfSelection === "manual" ? itemswf : null,
        selection: wfSelection === "manual" ? selection : null,
        pr_workflow_details:
          clientId !== 5
            ? wfSelection !== "manual"
              ? preDefinedWorkflowSteps
              : null
            : null,

        wfSelection: clientId !== 5 ? wfSelection : "pre-defined", //  Jaslok has only pre-defined wf
        expenseType: expenseType,
        cpp_id: data.cpp_id,
      },
    ];

    var formData = new FormData();
    formData.append("data", JSON.stringify(pr_details));
    formData.append("pr_document", prDoc);
    for (let i = 0; i < newDocs.length; i++) {
      formData.append("supporting_documents", supportingDoc[i]);
    }
    dispatch(createPr(formData)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        history.push("/app/prs-list");
      } else {
        setLoading(false);
      }
    });
  };

  console.log("companyListForPr", companyListForPr);
  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Create Purchase Requisition
                </VendorCardTitle>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <VendorCardSubTitle style={{ marginTop: "0" }}>
                  Enter main details, item details, attach documents and define
                  workflow steps neccessary
                </VendorCardSubTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Main Details
                </VendorCardTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item md={6}>
                <VendorCardLabel>
                  Select {Company} <Super>*</Super>
                </VendorCardLabel>
                <GlobalAutocomplete
                  name="company_id"
                  control={control}
                  options={companyListForPr?.flatMap((el) => {
                    return {
                      key: el.id,
                      value: el?.company_name,
                    };
                  })}
                  placeholder={`Select ${Company.toLocaleLowerCase()}`}
                  rules={{
                    required: `${Company.toLocaleLowerCase()} is required`,
                  }}
                  isMultiple={false}
                />
              </Grid>
              {/* <Grid item md={6}>
                <VendorCardLabel>
                  PR Number<Super>*</Super>
                </VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter PR number"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="pr_number"
                  {...register("pr_number", {
                    required: "Please enter PR number",
                  })}
                  error={Boolean(errors?.pr_number)}
                  helperText={errors?.pr_number?.message}
                />
              </Grid> */}
              {clientId === 5 ? (
                <>
                  <Grid item md={6}>
                    <FormControl
                      variant="standard"
                      style={{ width: "100%" }}
                      error={Boolean(errors?.cpp_id)}
                    >
                      <VendorCardLabel>
                        Purchase Point <Super>*</Super>
                      </VendorCardLabel>
                      <Select
                        sx={{
                          fontFamily: "var(--font-family-reg)",
                          fontSize: "14px",
                        }}
                        displayEmpty
                        defaultValue=""
                        IconComponent={() => (
                          <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                        )}
                        labelId="demo-simple-select-standard-label"
                        id="cpp_id"
                        name="cpp_id"
                        error={Boolean(errors?.cpp_id)}
                        {...register("cpp_id", {
                          required: "Please select purchase point",
                        })}
                      >
                        <MenuItem value="">
                          <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                            Select Purchase Point
                          </span>
                        </MenuItem>
                        {purchasePointList.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            <SelectSpan>
                              {item["PurchasePointMaster.purchase_point"]}
                            </SelectSpan>
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error>
                        {errors.cpp_id?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Desired Delivery Date<Super>*</Super>
                  </VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      disableInjectingGlobalStyles: true,
                    }}
                    InputLabelProps={{
                      disableAnimation: true,
                      disableInjectingGlobalStyles: true,
                    }}
                    size="small"
                    variant="standard"
                    type="date"
                    style={{
                      width: "100%",
                      paddingTop: "3px",
                      borderBottom: errors?.desired_delivery_date
                        ? "0"
                        : "1px solid lightgray",
                    }}
                    name="desired_delivery_date"
                    placeholder="Select desired delivery date"
                    {...register("desired_delivery_date", {
                      required: "Please select desired delivery date",
                    })}
                    value={datevalue}
                    onChange={(e) => handleinvdate(e)}
                    inputProps={{
                      min: new Date(datevalue).toISOString().split("T")[0], // Set the minimum date
                    }}
                    error={Boolean(errors?.desired_delivery_date)}
                    helperText={errors?.desired_delivery_date?.message}
                  />
                </FormControl>
              </Grid>
              {clientId !== 5 ? (
                ""
              ) : (
                <Grid item md={6}>
                  <VendorCardLabel>
                    PR Amount <Super>*</Super>{" "}
                  </VendorCardLabel>
                  <TextField
                    type="number"
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter PR Amount"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    name="pr_amount"
                    {...register("pr_amount", {
                      required: "Please Enter PR Amount",
                    })}
                    error={Boolean(errors?.pr_amount)}
                    helperText={errors?.pr_amount?.message}
                  />
                </Grid>
              )}
            </Grid>
            <br />
            {clientId === 5 ? (
              <>
                <VendorCardLabel>Select the type of expense</VendorCardLabel>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <RadioGroup
                      value={expenseType}
                      onChange={handleExpenseSelectionChange}
                      row
                    >
                      <FormControlLabel
                        value="capex"
                        control={<Radio />}
                        label={
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "0.9rem",
                              fontFamily: "Arial, sans-serif",
                            }}
                          >
                            CapEx
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="opex"
                        control={<Radio />}
                        label={
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "0.9rem",
                              fontFamily: "Arial, sans-serif",
                            }}
                          >
                            OpEx
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <br />
              </>
            ) : (
              ""
            )}

            <Grid container spacing={1}>
              <Grid item md={12}>
                <VendorCardLabel>Shipping Address</VendorCardLabel>

                <TextField
                  multiline
                  rows={3}
                  placeholder="Enter shipping address"
                  style={{
                    width: "100%",
                    paddingTop: "3px",
                    fontSize: "8px",
                    fontFamily: "var(--font-family-reg)",
                  }}
                  value={shippingAddress}
                  onChange={handleShippingChange}
                  disabled={clientId === 5}
                  name="shipping_address"
                  inputRef={register("shipping_address", { required: false })}
                />
              </Grid>
            </Grid>
            <br />
            {clientId !== 5 ? (
              <>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <VendorCardLabel>Billing Address</VendorCardLabel>
                    <TextField
                      multiline
                      rows={3}
                      placeholder="Enter billing address"
                      style={{
                        width: "100%",
                        paddingTop: "3px",
                        fontSize: "8px",
                        fontFamily: "var(--font-family-reg)",
                      }}
                      name="billing_address"
                      {...register("billing_address", {
                        required: false,
                      })}
                    />
                  </Grid>
                </Grid>{" "}
                <br />
              </>
            ) : (
              ""
            )}

            <>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <VendorCardTitle style={{ paddingBottom: "0" }}>
                    Item Details
                  </VendorCardTitle>
                </Grid>
              </Grid>
              <br />
              <CustomTableContainer component={Card}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: "#e1ebfa",
                      }}
                    >
                      <TableCell width="10%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Action</TableHeaderFont>
                      </TableCell>
                      <TableCell width="10%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Sr. No.</TableHeaderFont>
                      </TableCell>
                      <TableCell width="30%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Item Description</TableHeaderFont>
                      </TableCell>
                      <TableCell width="10%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Req Qty</TableHeaderFont>
                      </TableCell>
                      <TableCell width="10%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>UOM</TableHeaderFont>
                      </TableCell>
                      <TableCell width="10%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>Frequency</TableHeaderFont>
                      </TableCell>
                      <TableCell width="20%" style={{ textAlign: "center" }}>
                        <TableHeaderFont>
                          Purpose & Justification
                        </TableHeaderFont>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor: "#fafcff",
                        }}
                      >
                        <TableCell width="10%">
                          <CustomButton
                            className="invoiceErrbutn"
                            color="error"
                            variant="outlined"
                            size="small"
                            onClick={() => handleRemoveItem(index)}
                            style={{ marginLeft: "3px ", textAlign: "center" }}
                          >
                            Remove
                          </CustomButton>
                        </TableCell>
                        <TableCell
                          width="10%"
                          style={{
                            fontFamily: "var(--font-family-reg)",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell width="30%">
                          <GlobalAutocomplete
                            name="item_name"
                            control={control}
                            options={item_list?.flatMap((el) => {
                              return {
                                key: el.id,
                                value: el?.Description,
                              };
                            })}
                            // placeholder={`Select Item`}
                            rules={{
                              required: `Item Description is required`,
                            }}
                            variant="outlined"
                            isMultiple={false}
                            label="Type Item Name"
                            onInputChange={(_, newValue) => {
                              if (newValue.length >= 4) {
                                dispatch(getMaterials(newValue));
                              }
                              handleInputChange(index, "item_name", newValue);
                            }}
                            sx={{
                              fontWeight: "fontWeightLight",
                              "& .MuiAutocomplete-inputRoot": {
                                padding: "2px 4px", // Adjust padding to decrease height
                                "& .MuiOutlinedInput-input": {
                                  padding: "2px 4px", // Adjust padding inside the input
                                },
                              },
                              "& .MuiInputLabel-root": {
                                top: "-4px", // Adjust the position of the label
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell width="10%">
                          <input
                            type="number"
                            value={item.quantity}
                            min={0}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "quantity",
                                parseInt(e.target.value, 10)
                              )
                            }
                            style={{
                              border: "1px solid #ccc",
                              padding: "5px",
                              borderRadius: "5px",
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "center",
                              width: "80px",
                            }}
                          />
                        </TableCell>
                        <TableCell width="10%">
                          <select
                            value={item.unit_of_measurement}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "unit_of_measurement",
                                e.target.value
                              )
                            }
                            style={{
                              border: "1px solid #ccc",
                              padding: "5px",
                              borderRadius: "5px",
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "left",
                              width: "80px",
                            }}
                            required
                          >
                            <option
                              value=""
                              style={{ fontSize: "12px", color: "#A5A5BA" }}
                            >
                              Select Unit of Measurement
                            </option>
                            <option value="PCS" style={{ fontSize: "12px" }}>
                              PCS
                            </option>
                            <option value="MTR" style={{ fontSize: "12px" }}>
                              MTR
                            </option>
                            <option value="PAC" style={{ fontSize: "12px" }}>
                              PAC
                            </option>
                            <option value="UNIT" style={{ fontSize: "12px" }}>
                              UNIT
                            </option>
                            <option value="NOS." style={{ fontSize: "12px" }}>
                              NOS.
                            </option>
                            <option value="KGS" style={{ fontSize: "12px" }}>
                              KGS
                            </option>
                            <option value="LTR" style={{ fontSize: "12px" }}>
                              LTR
                            </option>
                            <option value="SQF" style={{ fontSize: "12px" }}>
                              SQF
                            </option>
                            <option value="ROLL" style={{ fontSize: "12px" }}>
                              ROLL
                            </option>
                            <option value="SET" style={{ fontSize: "12px" }}>
                              SET
                            </option>
                            <option value="TIN" style={{ fontSize: "12px" }}>
                              TIN
                            </option>
                            <option value="PGS" style={{ fontSize: "12px" }}>
                              PGS
                            </option>
                            <option value="PRS" style={{ fontSize: "12px" }}>
                              PRS
                            </option>
                            <option value="BLADE" style={{ fontSize: "12px" }}>
                              BLADE
                            </option>
                            <option value="DRM" style={{ fontSize: "12px" }}>
                              DRM
                            </option>
                            <option value="MTS" style={{ fontSize: "12px" }}>
                              MTS
                            </option>
                            <option value="CBM" style={{ fontSize: "12px" }}>
                              CBM
                            </option>
                            <option value="CFT" style={{ fontSize: "12px" }}>
                              CFT
                            </option>
                            <option value="SQM" style={{ fontSize: "12px" }}>
                              SQM
                            </option>
                            <option value="OTH" style={{ fontSize: "12px" }}>
                              OTH
                            </option>
                            <option value="BAG" style={{ fontSize: "12px" }}>
                              BAG
                            </option>
                            <option value="FT" style={{ fontSize: "12px" }}>
                              FT
                            </option>
                            <option value="FEET'" style={{ fontSize: "12px" }}>
                              FEET'
                            </option>
                            <option value="ROL" style={{ fontSize: "12px" }}>
                              ROL
                            </option>
                          </select>
                        </TableCell>
                        <TableCell width="10%">
                          <select
                            value={item.frequency}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "frequency",
                                e.target.value
                              )
                            }
                            style={{
                              border: "1px solid #ccc",
                              padding: "5px",
                              borderRadius: "5px",
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "left",
                              width: "80px",
                            }}
                            required
                          >
                            <option
                              value=""
                              style={{ fontSize: "12px", color: "#A5A5BA" }}
                            >
                              Select frequency
                            </option>
                            <option
                              value="annually"
                              style={{ fontSize: "12px" }}
                            >
                              annually
                            </option>
                            <option
                              value="quaterly"
                              style={{ fontSize: "12px" }}
                            >
                              quaterly
                            </option>
                            <option
                              value="monthly"
                              style={{ fontSize: "12px" }}
                            >
                              monthly
                            </option>
                            <option value="once" style={{ fontSize: "12px" }}>
                              once
                            </option>
                          </select>
                        </TableCell>
                        <TableCell width="20%">
                          <input
                            type="text"
                            value={item.purpose}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "purpose",
                                e.target.value
                              )
                            }
                            style={{
                              border: "1px solid #ccc",
                              padding: "5px",
                              borderRadius: "5px",
                              fontFamily: "var(--font-family-reg)",
                              fontSize: "12px",
                              textAlign: "left",
                              width: "250px",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>

              <CustomButton
                size="small"
                color="primary"
                variant="outlined"
                onClick={handleAddItem}
                className="invoiceErrbutn"
                style={{ marginTop: "10px " }}
              >
                Add Item
              </CustomButton>

              <br />
              <br />
              <br />
            </>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <VendorCardTitle style={{ paddingBottom: "0" }}>
                  Other Details
                </VendorCardTitle>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3}>
              {clientId !== 5 ? (
                <Grid item md={6}>
                  <VendorCardLabel>Preferred Supplier</VendorCardLabel>
                  <TextField
                    sx={{
                      input: {
                        fontSize: "14px",
                        fontFamily: "var(--font-family-reg)",
                      },
                    }}
                    placeholder="Enter preferred supplier"
                    size="small"
                    variant="standard"
                    style={{ width: "100%", paddingTop: "3px" }}
                    name="preferred_supplier"
                    {...register("preferred_supplier", {
                      required: false,
                    })}
                  />
                </Grid>
              ) : (
                ""
              )}

              <Grid item md={6}>
                <VendorCardLabel>Reason of Purchase</VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter reason of purchase"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="reason_of_purchase"
                  {...register("reason_of_purchase", {
                    required: false,
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl variant="standard" style={{ width: "100%" }}>
                  <VendorCardLabel>
                    Urgency Level<Super>*</Super>
                  </VendorCardLabel>
                  <Select
                    sx={{
                      fontFamily: "var(--font-family-reg)",
                      fontSize: "14px",
                    }}
                    displayEmpty
                    defaultValue=""
                    IconComponent={() => (
                      <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                    )}
                    labelId="demo-simple-select-standard-label"
                    id="urgency_level"
                    name="urgency_level"
                    {...register("urgency_level", {
                      required: "Please select urgency level",
                    })}
                    error={Boolean(errors?.urgency_level)}
                    helperText={errors?.urgency_level?.message}
                  >
                    <MenuItem value="">
                      <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                        Select level
                      </span>
                    </MenuItem>
                    <MenuItem value="high">
                      <SelectSpan>High</SelectSpan>{" "}
                    </MenuItem>
                    <MenuItem value="medium">
                      <SelectSpan>Medium</SelectSpan>{" "}
                    </MenuItem>
                    <MenuItem value="low">
                      <SelectSpan>Low</SelectSpan>{" "}
                    </MenuItem>
                  </Select>
                  <FormHelperText error>{errors.type?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <VendorCardLabel>Remarks</VendorCardLabel>
                <TextField
                  sx={{
                    input: {
                      fontSize: "14px",
                      fontFamily: "var(--font-family-reg)",
                    },
                  }}
                  placeholder="Enter remarks"
                  size="small"
                  variant="standard"
                  style={{ width: "100%", paddingTop: "3px" }}
                  name="remarks"
                  {...register("remarks", {
                    required: false,
                  })}
                />
              </Grid>
            </Grid>
            <br />
            <VendorCardTitle style={{ paddingBottom: "20px" }}>
              Documents
            </VendorCardTitle>
            <Grid container spacing={3}>
              {clientId !== 5 && clientId !== 3 ? (
                <>
                  <Grid item xs={4}>
                    <span
                      variant="outlined"
                      component="span"
                      style={{ width: "50%" }}
                    >
                      <DocumentLabels style={{ marginTop: "10px" }}>
                        Purchase Requisition Document<Super>*</Super>
                      </DocumentLabels>
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      variant="standard"
                      style={{ width: "100%" }}
                      error={Boolean(errors?.pr_document)}
                    >
                      {prDoc !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#4945FF",
                              fontSize: "13px",
                              fontFamily: "var(--font-family-reg)",
                            }}
                          >
                            <Chip
                              style={{
                                width: "150px",
                                color: "#4945FF",
                                margin: "5px",
                                borderRadius: "5px",
                                border: "1px solid #D9D8FF",
                                background: "#F0F0FF",
                              }}
                              label={prDoc.name}
                              value={prDoc.name}
                              name="chips"
                            />
                            <HighlightOffIcon
                              sx={{ color: "#4945FF", paddingBottom: "2px" }}
                              fontSize="medium"
                              onClick={(e) => setPrDoc("")}
                            />
                          </span>
                        </>
                      ) : (
                        <label htmlFor="pr_document">
                          <Input
                            {...register("pr_document", {
                              required: "Please upload PR document",
                            })}
                            accept="*"
                            id="pr_document"
                            onChange={(e) => setPrDoc(e.target.files[0])}
                            type="file"
                          />
                          <StyledOutlineButton
                            variant="outlined"
                            component="span"
                          >
                            Upload
                          </StyledOutlineButton>
                        </label>
                      )}
                      {prDoc === "" ? (
                        <FormHelperText error>
                          {errors.pr_document?.message}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
            {newDocs.map((item, index) => (
              <>
                <br />
                <Grid container spacing={3} key={index}>
                  <>
                    <Grid item xs={4}>
                      <span
                        variant="outlined"
                        component="span"
                        style={{ width: "50%" }}
                      >
                        <DocumentLabels style={{ marginTop: "10px" }}>
                          Supporting Document
                        </DocumentLabels>
                      </span>
                    </Grid>
                    {supportingDoc[index] !== "" &&
                    supportingDoc[index] !== undefined ? (
                      <>
                        <Grid item xs={3}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <span
                              style={{
                                color: "#4945FF",
                                fontSize: "13px",
                                fontFamily: "var(--font-family-reg)",
                              }}
                            >
                              <Chip
                                style={{
                                  width: "150px",
                                  color: "#4945FF",
                                  margin: "5px",
                                  borderRadius: "5px",
                                  border: "1px solid #D9D8FF",
                                  background: "#F0F0FF",
                                }}
                                label={supportingDoc[index].name}
                                value={supportingDoc[index].name}
                                name="chips"
                              />
                              <HighlightOffIcon
                                sx={{ color: "#4945FF", paddingBottom: "2px" }}
                                fontSize="medium"
                                onClick={(e) => handleclearDoc(e, index)}
                              />
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={2}>
                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                          >
                            <label>
                              <Input
                                accept="*"
                                id={`supporting_doc_${index}`}
                                onChange={(e) => handlechangeDoc(e, index)}
                                type="file"
                              />
                              <StyledOutlineButton
                                variant="outlined"
                                component="span"
                              >
                                Upload
                              </StyledOutlineButton>
                            </label>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          {index === 0 ? (
                            <CustomButton
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={handleOnClick}
                              className="invoiceErrbutn"
                              style={{ marginTop: "10px " }}
                            >
                              Add Document
                            </CustomButton>
                          ) : (
                            <CustomButton
                              className="invoiceErrbutn"
                              color="error"
                              variant="outlined"
                              size="small"
                              onClick={(e) => handleRemove(e, index)}
                              style={{ marginTop: "10px " }}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              </>
            ))}
            <br />
            <br />
            {clientId !== 5 ? (
              <>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <VendorCardTitle style={{ paddingBottom: "0" }}>
                      Define Workflow Steps
                    </VendorCardTitle>
                  </Grid>
                </Grid>
                {role !== "super admin" ? (
                  <>
                    {" "}
                    <Grid container spacing={2}>
                      <Grid item md={8}>
                        <VendorCardSubTitle style={{ marginTop: "0" }}>
                          Select type of workflow
                        </VendorCardSubTitle>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <RadioGroup
                          value={wfSelection}
                          onChange={handleWfsSelectionChange}
                          row
                        >
                          <FormControlLabel
                            value="manual"
                            control={<Radio />}
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.9rem",
                                  fontFamily: "Arial, sans-serif",
                                }}
                              >
                                Manual
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="predefined"
                            control={<Radio />}
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.9rem",
                                  fontFamily: "Arial, sans-serif",
                                }}
                              >
                                Pre-defined
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                {wfSelection === "manual" ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item md={8}>
                        <VendorCardSubTitle style={{ marginTop: "0" }}>
                          Select how you want to define workflow steps
                        </VendorCardSubTitle>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <RadioGroup
                          value={selection}
                          onChange={handleSelectionChange}
                          row
                        >
                          <FormControlLabel
                            value="role"
                            control={<Radio />}
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.9rem",
                                  fontFamily: "Arial, sans-serif",
                                }}
                              >
                                Role-wise
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="person"
                            control={<Radio />}
                            label={
                              <Typography
                                variant="body1"
                                style={{
                                  fontSize: "0.9rem",
                                  fontFamily: "Arial, sans-serif",
                                }}
                              >
                                Person-wise
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={8}>
                        <VendorCardSubTitle style={{ marginTop: "0" }}>
                          Select the sequence of the approvers for the workflow
                        </VendorCardSubTitle>
                      </Grid>
                    </Grid>
                    <>
                      <CustomTableContainer component={Card}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow
                              style={{
                                backgroundColor: "#e1ebfa",
                              }}
                            >
                              <TableCell
                                width="10%"
                                style={{ textAlign: "center" }}
                              >
                                <TableHeaderFont>Action</TableHeaderFont>
                              </TableCell>
                              <TableCell
                                width="10%"
                                style={{ textAlign: "center" }}
                              >
                                <TableHeaderFont>Step No.</TableHeaderFont>
                              </TableCell>
                              <TableCell
                                width="20%"
                                style={{ textAlign: "center" }}
                              >
                                <TableHeaderFont>
                                  {selection === "person" ? "Approver" : "Role"}
                                </TableHeaderFont>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </CustomTableContainer>
                      <TableBody>
                        {itemswf.map((item, index) => (
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor: "#fafcff",
                            }}
                          >
                            <TableCell width="10%">
                              <CustomButton
                                className="invoiceErrbutn"
                                color="error"
                                variant="outlined"
                                size="small"
                                onClick={() => handleRemoveItemWF(index)}
                                style={{
                                  marginLeft: "3px ",
                                  textAlign: "center",
                                }}
                              >
                                Remove
                              </CustomButton>
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: "var(--font-family-reg)",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell width="10%">
                              <select
                                value={
                                  selection === "role"
                                    ? item.role_name
                                    : item.contact_person_email
                                }
                                onChange={(e) =>
                                  handleInputChangewf(
                                    index,
                                    selection === "role"
                                      ? "role_name"
                                      : "contact_person_email",
                                    e.target.value
                                  )
                                }
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  fontFamily: "var(--font-family-reg)",
                                  fontSize: "12px",
                                  textAlign: "left",
                                  width: "380px",
                                }}
                                required
                              >
                                <option
                                  value=""
                                  style={{ fontSize: "12px", color: "#A5A5BA" }}
                                >
                                  Select approver
                                </option>
                                {item_list_wf.map((item) => (
                                  <option
                                    key={
                                      selection === "role"
                                        ? item.role_name
                                        : item.contact_person_email
                                    }
                                    value={
                                      selection === "role"
                                        ? item.role_name
                                        : item.contact_person_email
                                    }
                                    style={{ fontSize: "12px" }}
                                    disabled={selectedEmails.includes(
                                      selection === "role"
                                        ? item.role_name
                                        : item.contact_person_email
                                    )}
                                  >
                                    {selection === "role"
                                      ? item.role_name
                                      : item.contact_person_email}
                                  </option>
                                ))}
                              </select>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <CustomButton
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={handleAddItemWF}
                        className="invoiceErrbutn"
                        style={{ marginTop: "10px " }}
                      >
                        Add Row
                      </CustomButton>

                      <br />
                      <br />
                      <br />
                    </>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item md={8}>
                        <VendorCardSubTitle style={{ marginTop: "0" }}>
                          Select a pre-defined workflow
                        </VendorCardSubTitle>
                      </Grid>
                    </Grid>
                    <Select
                      sx={{
                        fontFamily: "var(--font-family-reg)",
                        fontSize: "14px",
                        width: "100%",
                      }}
                      displayEmpty
                      defaultValue=""
                      IconComponent={() => (
                        <ExpandMoreIcon style={{ color: "#A5A5BA" }} />
                      )}
                      labelId="demo-simple-select-standard-label"
                      id="workflow_id"
                      name="workflow_id"
                      error={Boolean(errors?.workflow_id)}
                      {...register("workflow_id", {
                        required: "Please select workflow",
                      })}
                      onChange={(e) => handleOnchange(e)}
                    >
                      <MenuItem value="">
                        <span style={{ fontSize: "14px", color: "#A5A5BA" }}>
                          Select workflow
                        </span>
                      </MenuItem>
                      {pr_workflow_details.map((item, index) => (
                        <MenuItem value={item.id} key={index}>
                          <SelectSpan>{item.name}</SelectSpan>
                        </MenuItem>
                      ))}
                    </Select>
                    <br />
                    <br />
                    <VendorCardTitle>Pre-defined Steps</VendorCardTitle>
                    <CustomTableContainer component={Card}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow
                            style={{
                              backgroundColor: "#e1ebfa",
                            }}
                          >
                            <TableCell
                              width="10%"
                              style={{ textAlign: "center" }}
                            >
                              <TableHeaderFont>Step No.</TableHeaderFont>
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{ textAlign: "center" }}
                            >
                              <TableHeaderFont>Name</TableHeaderFont>
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{ textAlign: "center" }}
                            >
                              <TableHeaderFont>Email</TableHeaderFont>
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{ textAlign: "center" }}
                            >
                              <TableHeaderFont>Department</TableHeaderFont>
                            </TableCell>
                            <TableCell
                              width="20%"
                              style={{ textAlign: "center" }}
                            >
                              <TableHeaderFont>Role</TableHeaderFont>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {preDefinedWorkflowSteps?.length ? (
                            preDefinedWorkflowSteps.map((row, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    width="10%"
                                    style={{
                                      fontFamily: "var(--font-family-reg)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.step !== null
                                      ? row.step
                                      : "Not Applicable"}
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{
                                      fontFamily: "var(--font-family-reg)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.name !== null
                                      ? row.name
                                      : "Not Applicable"}
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{
                                      fontFamily: "var(--font-family-reg)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.contact_person_email !== null
                                      ? row.contact_person_email
                                      : "Not Applicable"}
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{
                                      fontFamily: "var(--font-family-reg)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.department !== null
                                      ? row.department
                                      : "Not Applicable"}
                                  </TableCell>
                                  <TableCell
                                    width="20%"
                                    style={{
                                      fontFamily: "var(--font-family-reg)",
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.role_name !== null
                                      ? row.role_name
                                      : "Not Applicable"}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow></TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </CustomTableContainer>
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </CardContent>
        </StyledCard>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pt: 2,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: "1 1 auto" }} />

          {/* <LoadingButton
            size="small"
            color="secondary"
            onClick={handleClick}
            loading={loading}
            loadingPosition="start"
            variant="contained"
          >
            Validate
          </LoadingButton> */}
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "200px",
              height: "40px",
            }}
            color="primary"
            variant="contained"
            type="submit"
            loading={loading}
            loadingPosition="start"
            disabled={items[0]?.quantity === 0 || !items.length}
          >
            Upload PR
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

export default AdminPrsContent;
